import React from "react";

import "./index.css";

const Banner: React.FC = () => {
  return (
    <div className="v2-banner">
      <p>
        🎉 Exciting news! V2 will be released in October. Grab it now before the
        price increases with the launch of v2! 🚀
      </p>
    </div>
  );
};

export default Banner;
